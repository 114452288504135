import { useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { v4 as uuid } from 'uuid';
import './App.scss';

function App() {
  const [activeColumn, setActiveColumn] = useState(-1);
  const [timeZones, setTimeZones] = useState([]);
  const [hours, setHours] = useState([]);
  const [hoursShowIndexes, setHoursShowIndexes] = useState([0, 1, 2, 3, 4]);

  const activateColumn = index => {
    setActiveColumn(index);
  };

  const deactivateColumn = () => {
    setActiveColumn(-1);
  };

  const getHour = (hourId, timeShift) => {
    const newHour = hourId + timeShift;

    if (newHour < 0) {
      return 24 + newHour;
    }

    if (newHour > 23) {
      return newHour - 24;
    }

    return newHour;
  };

  const getHourName = hour => {
    const newHour = hour === 23 ? 0 : hour + 1;
    return `${`0${hour}`.slice(-2)}:00-${`0${newHour}`.slice(-2)}:00`;
  };

  const getName = timeZone => {
    return (
      <div className="time-zones-table-body-cell-title">
        <span>{timeZone.name}</span>
        &nbsp;
        <span>
          {timeZone.hoursSTD > 0 ? '+' : ''}
          {timeZone.hoursSTD}:00&nbsp;
        </span>
      </div>
    );
  };

  const getDayPartName = hour => {
    if (hour >= 8 && hour < 9) {
      return 'morning';
    }

    if (hour >= 9 && hour < 18) {
      return 'afternoon';
    }

    if (hour >= 18 && hour < 19) {
      return 'evening';
    }

    return 'night';
  };

  const selectTimeZone = timeZone => {
    const newTimeZones = timeZones.map(timeZoneItem => {
      if (timeZoneItem.id === timeZone.id) {
        timeZoneItem.isSelected = !timeZoneItem.isSelected;
      }

      return timeZoneItem;
    });

    setTimeZones(newTimeZones);
  };

  const next = () => {
    if (hoursShowIndexes[hoursShowIndexes.length - 1] === 23) {
      return;
    }

    setHoursShowIndexes(hoursShowIndexes.map(index => index + 1));
  };

  const prev = () => {
    if (hoursShowIndexes[0] === 0) {
      return;
    }

    setHoursShowIndexes(hoursShowIndexes.map(index => index - 1));
  };

  useEffect(() => {
    const timeZonesNames = {
      '-12': 'Etc/GMT+12',
      '-11': 'Pacific/Midway',
      '-10': 'US/Hawaii',
      '-9': 'US/Alaska',
      '-8': 'US/Pacific',
      '-7': 'US/Mountain',
      '-6': 'US/Central',
      '-5': 'US/Eastern',
      '-4': 'America/Barbados',
      '-3': 'America/Argentina/Buenos_Aires',
      '-2': 'Greenland',
      '-1': 'Atlantic/Azores',
      0: 'UTC/Greenwich',
      1: 'Europe',
      2: 'Kyiv',
      3: 'Istanbul',
      4: 'Dubai',
      5: 'Mawson',
      6: 'Chagos',
      7: 'Jakarta',
      8: 'Hong Kong',
      9: 'Tokyo',
      10: 'Sydney',
      11: 'Bougainville',
      12: 'Auckland',
      13: 'Kanton',
      14: 'Kiritimati'
    };
    const newTimeZones = [];

    for (let i = -12; i <= 14; i++) {
      newTimeZones.push({
        id: uuid(),
        name: timeZonesNames[i],
        hoursSTD: i,
        hoursDST: i,
        isSelected: [2, 4, 8, -5, -8].includes(i) ? true : false
      });
    }

    setTimeZones(newTimeZones);

    const newHoures = [];

    for (let i = 0; i < 24; i++) {
      newHoures.push(i);
    }

    setHours(newHoures);
  }, [setTimeZones, setHours]);

  return (
    <Container>
      <Row>
        <Col>
          <h1 className="title">Time Slot</h1>

          <div className="time-zones">
            <div className="time-zones-prev" onClick={prev}>
              &larr;
            </div>
            <div className="time-zones-current">
              {timeZones
                .filter(timeZone => timeZone.hoursSTD === 0)
                .map(timeZone => (
                  <div
                    key={`time-zone-row-${timeZone.id}`}
                    className={`time-zone-row ${
                      timeZone.hoursSTD === 0 ? 'utc' : ''
                    }`}
                  >
                    <div className="time-zone-name">{getName(timeZone)}</div>
                    <div className="time-zone-slots">
                      {hours
                        .filter(hour => hoursShowIndexes.includes(hour))
                        .map(hour => {
                          return (
                            <div
                              key={`time-zone-${timeZone.id}-slot-${hour}`}
                              onMouseEnter={() => activateColumn(hour)}
                              onMouseLeave={() => deactivateColumn()}
                              className={`time-zone-slot ${
                                activeColumn === hour ? 'active' : ''
                              } ${getDayPartName(
                                getHour(hour, timeZone.hoursSTD)
                              )}`}
                            >
                              {getHourName(getHour(hour, timeZone.hoursSTD))}
                            </div>
                          );
                        })}
                    </div>
                  </div>
                ))}

              <hr />

              {timeZones
                .filter(timeZone => timeZone.isSelected)
                .map(timeZone => (
                  <div
                    key={`time-zone-row-${timeZone.id}`}
                    className={`time-zone-row ${
                      timeZone.hoursSTD === 0 ? 'utc' : ''
                    }`}
                  >
                    <div className="time-zone-name">{getName(timeZone)}</div>
                    <div className="time-zone-slots">
                      {hours
                        .filter(hour => hoursShowIndexes.includes(hour))
                        .map(hour => {
                          return (
                            <div
                              key={`time-zone-${timeZone.id}-slot-${hour}`}
                              onMouseEnter={() => activateColumn(hour)}
                              onMouseLeave={() => deactivateColumn()}
                              className={`time-zone-slot ${
                                activeColumn === hour ? 'active' : ''
                              } ${getDayPartName(
                                getHour(hour, timeZone.hoursSTD)
                              )}`}
                            >
                              {getHourName(getHour(hour, timeZone.hoursSTD))}
                            </div>
                          );
                        })}
                    </div>
                  </div>
                ))}
            </div>
            <div className="time-zones-next" onClick={next}>
              &rarr;
            </div>
          </div>

          <div className="time-zones-buttons">
            {timeZones.map(timeZone => (
              <div
                key={`time-zone-button-${timeZone.id}`}
                className={`time-zones-button ${
                  timeZone.isSelected ? 'selected' : ''
                } ${timeZone.hoursSTD === 0 ? 'utc' : ''}`}
                onClick={() => selectTimeZone(timeZone)}
              >
                {timeZone.hoursSTD}
                <span className="time-zones-button-name">
                  GMT{timeZone.hoursSTD >= 0 ? '+' : ''}
                  {timeZone.hoursSTD}
                </span>
              </div>
            ))}
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default App;
